import React from 'react'
import { Link } from 'gatsby'
import { Box, Text } from 'rebass'
import { formatDate } from '../../../../__utils__/helpers'
import Heading from '../../Heading'
import Button from '../../Button'
import BodyText from '../../BodyText'
import { StyledBlogArticleSummary, ThumbnailImage } from './style'


const BlogArticleSummary = ({
  title,
  description,
  slug,
  categories,
  publishDate,
  mastheadImage,
  ...props
}) => (
  <StyledBlogArticleSummary {...props}>
    <Box p={[5]} width={1}>
      <Link to={`/blog/${slug}/`}>
        <ThumbnailImage thumbnailImage={mastheadImage.file.url} />
        <Heading fontSize={[3, 3]} postsymbol="">
          {title}
        </Heading>
      </Link>
      <BodyText>{description}</BodyText>
      <Text color="#ff7a7a" fontSize={[1]} fontWeight="900" mb={[2]}>
        {formatDate(publishDate)}
      </Text>
      {categories && categories.length > 0 && (
        <ul>
          {categories.map(cat => (
            <li key={cat.contentfulid}>
              <Link to={`/blog/${cat.contentfulid}/`}>{`#${cat.title}`}</Link>
            </li>
          ))}
        </ul>
      )}

      <Link to={`/blog/${slug}/`}>
        <Button>Read article</Button>
      </Link>
    </Box>
  </StyledBlogArticleSummary>
)

export { BlogArticleSummary as default, StyledBlogArticleSummary }
