import React from 'react'
import { graphql } from 'gatsby'
import { Flex, Box } from 'rebass'
import styled, { ThemeProvider } from 'styled-components'

import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import Heading, { StyledHeading } from '../components/elements/Heading'
import CategorySelector from '../components/elements/CategorySelector'
import ArticleGrid from '../components/blocks/ArticleGrid'
import theme from '../themes'

const StyledBlogPage = styled.div`
  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }

    &:active {
      color: ${props => props.theme.colors.linkActive};
    }
  }

  > ${Flex} {
    > ${Box} {
      max-width: 1168px;
      width: 100%;
    }
  }

  .sectionHeading {
    ${StyledHeading} {
      color: black;
      position: relative;

      &:after {
        color: black;
      }

      span {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`

const BlogPage = ({ data, pageContext }) => {
  const { metaTitle, metaDescription, metaFeaturedImage } = data.currentCategory.seoMetadata
  const {
    currentCategoryId, currentCategoryTitle, categories, categoryArticles
  } = pageContext

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title={metaTitle}
        description={metaDescription.metaDescription}
        metaImage={metaFeaturedImage && metaFeaturedImage.file.url}
        canonical={`https://powershifter.com/blog/${currentCategoryId}/`}
      />
      <StyledBlogPage>
        <Header variant="transparent" hasLightBackground />
        <Flex mt={[1, null, null, 1]}>
          <Box mx="auto" pt={['80px', null, null, null]} width={8 / 12} />
        </Flex>
        <Flex className="sectionHeading">
          <Box mx="auto" my={[8]} width={1} px={[4, null, null, null, 7]}>
            <Heading fontSize={[4, 4, 5]} is="h1" postsymbol="" ismarkdown="false">
              Showing all articles for
              <span>
                {' '}
                {`#${currentCategoryTitle}`}
              </span>
            </Heading>
          </Box>
        </Flex>
        <CategorySelector
          categories={categories}
          currentCategory={currentCategoryId}
        />
        <ArticleGrid articles={categoryArticles} />
        <Footer />
      </StyledBlogPage>
    </ThemeProvider>
  )
}

export default BlogPage

export const query = graphql`
  query($currentCategoryId: String!) {
    currentCategory: contentfulBlogCategory(contentfulid: { eq: $currentCategoryId }) {
      seoMetadata {
        metaDescription {
          metaDescription
        }
        metaFeaturedImage {
          file {
            url
          }
          description
        }
        metaTitle
      }
    }
  }
`
