import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Flex, Box, Image, Text
} from 'rebass'
import {
  StyledCategorySelector,
  StyledCategorySelectorContainer,
  StyledCategorySelectorNavBar,
  StyledCategorySelectorToggle,
  CategorySelectorIcon,
  StyledClearCategoryLink,
  CategoryLink,
  CategoriesPanel
} from './styles'
import Button from '../Button'

const CategorySelector = ({ currentCategory, categories, ...props }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [hasResized, setHasResized] = useState(false)
  const toggleIsCollapsed = () => {
    setIsCollapsed(prevState => !prevState)
    setHasResized(false)
  }

  const categoriesPanelRef = useRef(null)

  useEffect(() => {
    const refreshPanelHeight = () => {
      setHasResized(true)
    }
    window.addEventListener('resize', refreshPanelHeight)
    return () => window.removeEventListener('resize', refreshPanelHeight)
  }, [])

  return (
    <StyledCategorySelectorContainer>
      <StyledCategorySelector {...props}>
        <Box mx="auto" width={1} fontSize={0} fontWeight="bold" onClick={toggleIsCollapsed}>
          <StyledCategorySelectorNavBar
            flexDirection={['row']}
            alignItems={['center']}
            px={[3, null, null, null, 0]}
            py={[3]}
          >
            <StyledCategorySelectorToggle>
              {isCollapsed ? (
                <CategorySelectorIcon mr={4} src="/images/subnav-categories.svg" />
              ) : (
                <CategorySelectorIcon ml={['8px']} mr={4} src="/images/subnav-close.svg" />
              )}
              <Text className="categories-title" fontSize={[1]} fontWeight="900">
                {isCollapsed ? 'Categories' : 'Close Categories'}
              </Text>
            </StyledCategorySelectorToggle>
            {currentCategory ? (
              <StyledClearCategoryLink to="/blog/">
                <Image ml={['8px']} mr={4} src="/images/subnav-close.svg" />
                <Text className="categories-title" fontSize={[1]} fontWeight="900" postsymbol="">
                  Clear Filters
                </Text>
              </StyledClearCategoryLink>
            ) : null}
          </StyledCategorySelectorNavBar>
        </Box>
        <CategoriesPanel forwardRef={categoriesPanelRef} isCollapsed={isCollapsed} hasResized={hasResized}>
          <Box mx="auto" width={1}>
            <Flex flexDirection="row" alignItems="stretch" justifyContent="center" flexWrap="wrap">
              {categories.map(({ contentfulid, title }) => (
                <Flex
                  key={contentfulid}
                  width={['90%', 1 / 2, null, 0.4, 0.3]}
                  alignItems="center"
                  justifyContent="center"
                  flexWrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
                  m={1}
                >
                  <CategoryLink to={`/blog/${contentfulid}`} isCollapsed={isCollapsed}>
                    <Button
                      fullWidth
                      variant={currentCategory === contentfulid ? 'salmon' : 'inverted'}
                    >
                      {`#${title}`}
                    </Button>
                  </CategoryLink>
                </Flex>
              ))}
            </Flex>
          </Box>
        </CategoriesPanel>
      </StyledCategorySelector>
    </StyledCategorySelectorContainer>
  )
}

CategorySelector.defaultProps = {
  currentCategory: undefined
}

CategorySelector.propTypes = {
  categories: PropTypes.array.isRequired,
  currentCategory: PropTypes.string
}

export { CategorySelector as default, StyledCategorySelector }
