import styled from 'styled-components'
import { Box } from 'rebass'

export const StyledBlogArticleSummary = styled.div`
  background: #fff;
  border-radius: 20px;
  border: 2px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;
  z-index: 2;
  width: 100%;
  height: 100%;

  > ${Box}.masthead {
    height: 400px;
    width: 100%;
    background-color: #d8d8d8;
    background-size: cover;
    background-position: center center;
    border: none;
  }

  ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      flex-flow: column;
    }

    li {
      margin-right: 20px;

      a {
        white-space: nowrap;
      }
    }
  }

  h2 {
    &:first-child {
      display: block;
      font-size: 20px;
      color: ${props => props.theme.colors.salmon};
    }
  }
`
export const ThumbnailImage = styled.div`
  background-image: url(${props => props.thumbnailImage});
  height: 250px;
  margin: 0 0 1rem;
  position: relative;
  background-size: cover;
  background-position: center center;
  color: ${props => props.theme.colors.white};
  border-radius: 20px;
`
