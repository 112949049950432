import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'
import { Flex, Image } from 'rebass'
import { Link } from 'gatsby'

export const CategorySelectorIcon = styled(Image)``

export const StyledCategorySelector = styled.div`
  background-color: ${props => props.theme.colors.blue};
  color: white;
  cursor: pointer;
  user-select: none;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s 0.3s ease-out;

  :active {
    background-color: ${props => lighten(0.025, props.theme.colors.blue)};

    ${CategorySelectorIcon} {
      position: relative;
      left: 1px;
      top: 1px;
    }
  }

  & > div {
    &:first-of-type:hover {
      background-color: ${props => lighten(0.05, props.theme.colors.blue)};
    }
  }

  .categories-title {
    display: inline-block;
    color: white;
    margin: 0;
  }

`

export const CategoriesPanel = styled(({ forwardRef, isCollapsed, hasResized, ...props }) => <Flex {...props} ref={forwardRef} />)`
  margin: 0 auto;
  padding: ${({ isCollapsed }) => (isCollapsed ? 0 : '1rem 0 2rem')};
  overflow: hidden;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.blue};
  height: ${({ forwardRef, hasResized, isCollapsed }) => (isCollapsed ? 0 : (hasResized ? 'auto' : `${forwardRef.current.scrollHeight + 48}px`))};
  transition: all 0.25s ease-out;
`

export const StyledCategorySelectorContainer = styled.div`
  position: sticky;
  top: 80px;
  z-index: 99;
`

export const StyledCategorySelectorNavBar = styled(Flex)`
  margin: 0 auto;
  max-width: 1168px;
`

export const StyledCategorySelectorToggle = styled.div`
  img {
    display: inline-block;
  }
`

export const StyledClearCategoryLink = styled(Link)`
  margin-left: 40px;

  img {
    display: inline-block;
  }
`


export const CategoryLink = styled(({ isCollapsed, ...props }) => <Link {...props} />)`
  width: 100%;
  opacity: ${props => (props.isCollapsed ? 0 : 1)};
`
