import styled from 'styled-components'
import { Flex, Box } from 'rebass'

export const StyledArticleGrid = styled.div`
  > ${Flex} {
    > ${Box} {
      max-width: 1168px;
    }
  }

  ${Flex}.articles {
    margin: 0.25rem 0;
    @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
      margin: 4rem 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      width: 100%;

      li {
        margin-right: 10px;
      }
    }
  }

  .article-row-hidden {
    display: none;
  }

  .article-btn-hidden {
    display: none;
  }

  .article-btn-visible {
    display: inline-block;
  }
`
