import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass'

import BlogArticleSummary from '../../elements/blog/BlogArticleSummary'
import Button from '../../elements/Button'
import { toPairs } from '../../../__utils__/helpers'
import { StyledArticleGrid } from './styles'

const INITIAL_ROW_LIMIT = 5

const ArticleGrid = ({ articles }) => {
  const [rowLimit, setRowLimit] = useState(INITIAL_ROW_LIMIT)
  const articleRows = toPairs(articles)
  const hasHiddenArticles = articleRows.length > rowLimit

  // Scroll to newly loaded articles
  const firstNewRowRef = useRef(null)
  useEffect(() => {
    const SCROLL_OFFSET = -80
    if (firstNewRowRef.current && rowLimit > INITIAL_ROW_LIMIT) {
      window.scrollTo({ top: firstNewRowRef.current.offsetTop + SCROLL_OFFSET, behavior: 'smooth' })
    }
  }, [rowLimit])

  return (
    <StyledArticleGrid>
      <Flex className="articles">
        <Box mx="auto" width={1} mb={2}>
          {articleRows.map(([leftArticle, rightArticle], index) => (
            <Flex
              key={leftArticle.slug}
              ref={index === rowLimit - INITIAL_ROW_LIMIT ? firstNewRowRef : undefined}
              className={index < rowLimit ? 'article-row-visible' : 'article-row-hidden'}
              flexDirection={['column', null, null, 'row']}
              alignItems="stretch"
              justifyContent="space-between"
            >
              {leftArticle && (
                <Flex width={[1, null, null, 0.5]} mt={3} mb={[0, 0, 0, 3]} mr={[0, 0, 0, 3]} alignItems="center">
                  <BlogArticleSummary {...leftArticle} />
                </Flex>
              )}
              {leftArticle && rightArticle ? (
                <Flex width={[1, null, null, 0.5]} mt={3} mb={[0, 0, 0, 3]} ml={[0, 0, 0, 3]} alignItems="center">
                  <BlogArticleSummary {...rightArticle} />
                </Flex>
              ) : (
                <Flex width={[1, null, null, 0.5]} mt={3} mb={[0, 0, 0, 3]} ml={[0, 0, 0, 3]} alignItems="center" />
              )}
            </Flex>
          ))}
        </Box>
      </Flex>
      <Flex>
        <Box
          className={hasHiddenArticles ? 'article-btn-visible' : 'article-btn-hidden'}
          mx="auto"
          pb={[7]}
          pt={[5, 5, 5, 0]}
        >
          <Button onClick={() => setRowLimit(limit => limit + INITIAL_ROW_LIMIT)}>View more articles</Button>
        </Box>
      </Flex>
    </StyledArticleGrid>
  )
}

ArticleGrid.propTypes = {
  articles: PropTypes.array.isRequired
}

export default ArticleGrid
